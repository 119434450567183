import Link from 'next/link'
import Image from "next/legacy/image"
import { Box, Button, Flex, Grid, Text } from './primitives'
import { FC } from 'react'

interface IProp {
  hideLink?: boolean
}

const HeroSection: FC<IProp> = ({ hideLink }) => {
  return (
    <Flex
    as="section"
    css={{
      position: 'relative',
      width: '100%',
      height: 'auto',
      backgroundPosition: 'center center',
      backgroundImage: `url('https://i.ibb.co/nDDNxz5/herosectio-2n.png')`,
      backgroundSize: 'cover',
      '@media (max-width: 599px)': {
        display: 'none',
      },
      '@media (min-width: 600px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
        padding: '100px 64px',
      },
      }}
    >
      <Box css={{
        opacity: 0.5,
        backgroundImage: 'linear-gradient(109.6deg, #000 11.2%, $primary13 91.1%)',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        content: ' ',
      }}/>
      <Grid
        css={{
          gap: 32,
          '@xs': {
            flex: 1,
          },
          '@lg': {
            flex: 0.5,
          },
          zIndex: 1,
          position: 'relative'
        }}
      >
        <Flex align="center" css={{ gap: 20 }}>
          <Text
            style={{
              '@initial': 'h2',
               '@lg': 'h2'
            }}
            as="h1"
            css={{
              color: '$whiteA12', lineHeight: 1
            }}
          >
            Artopia Pro
          </Text>
         
        </Flex>

        <Text
          style="subtitle1"
          css={{
            lineHeight: 2,
            color: '$whiteA12',
            width: '100%',
            '@lg': { width: '50%' },
          }}
        >
          {`Aggregated Liquidity. Market Making. Instant Sell, Sweeps & Batch Orders. Trade Incredible NFTs like a pro`}
        </Text>
        {hideLink ?? (
          <Flex css={{ gap: 10 }}>
            
          </Flex>
        )}
      </Grid>
    </Flex>
  )
}

export default HeroSection